import React from "react";
import logo from "../../svg/logo.svg";
import "./index.scss";

export default function Header() {
  return (
    <header className="header">
      <img src={logo} className="header-logo" alt="logo" />
      <h1>
        <a href="/">#newProfilePic</a>
      </h1>
    </header>
  );
}
