import React, { Component, CSSProperties } from "react";
import LazyLoad from "react-lazyload";
import { IGalleryPhoto } from "../../types";
import "./index.scss";

interface ProfilePicProps {
  index: number;
  onClick: (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
    photo: any
  ) => void;
  photo: IGalleryPhoto;
  margin: string;
  direction: string;
  top: number;
  left: number;
}

export default class ProfilePic extends Component<
  ProfilePicProps,
  { error: boolean }
> {
  constructor(props: ProfilePicProps) {
    super(props);
    this.state = { error: false };
    this.onLoadError = this.onLoadError.bind(this);
  }
  onLoadError() {
    this.setState({
      error: true
    });
  }
  render() {
    if (this.state.error) {
      return "";
    }
    const containerStyle: CSSProperties = {
      position: "relative",
      left: "",
      top: ""
    };
    if (this.props.direction === "column") {
      containerStyle.position = "absolute";
      containerStyle.left = this.props.left;
      containerStyle.top = this.props.top;
    }

    const { margin, photo } = this.props;
    const { height, width, src, srcSet } = photo;
    // const srcSet = Object.keys(photo.picSizes)
    //   .map(key => `${src}:${key} ${photo.picSizes[key].w}w`)
    //   .join(",");
    const imageAttr = {
      src,
      srcSet
    };

    return (
      <div
        style={{ margin, height, width, ...containerStyle }}
        className="profile-pic"
        onClick={(ev: any) => this.props.onClick(ev, this.props)}
      >
        <LazyLoad height={height} offset={200} once>
          <img {...imageAttr} onError={this.onLoadError} />
        </LazyLoad>
        <p className="pic-attr">
          <a href={photo.expanded_url} target={"_twitter"}>
            @{photo.screen_name}
          </a>
        </p>
      </div>
    );
  }
}
