import React from "react";
import logo from "../../svg/logo.svg";
import "./index.scss";

export default function PicSizeSelector({
  size,
  onSizeChange
}: {
  size: string;
  onSizeChange: any;
}) {
  return (
    <ul className="size-selector" title="Select picture size">
      <li
        className={size === "small" ? "selected" : ""}
        onClick={() => onSizeChange("small")}
      >
        <img src={logo} className="size-icon" alt="s" />
      </li>
      <li
        className={size === "medium" ? "selected" : ""}
        onClick={() => onSizeChange("medium")}
      >
        <img src={logo} className="size-icon" alt="m" />
      </li>
      <li
        className={size === "large" ? "selected" : ""}
        onClick={() => onSizeChange("large")}
      >
        <img src={logo} className="size-icon" alt="l" />
      </li>
    </ul>
  );
}
