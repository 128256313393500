import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import { Timestamp, IPicTweet } from "../types";
import { PAGE_SIZE } from "../conf";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_PROJECT_ID
});

const functions = firebase.functions();
const db = firebase.firestore();
db.settings({
  timestampsInSnapshots: true
});

export const callTweetLoader = () =>
  functions.httpsCallable("api/tweet-loader")();

export const callTweetCleaner = () =>
  functions.httpsCallable("api/tweet-cleaner")();

export const callGetLatestTweets = () =>
  functions
    .httpsCallable(`api/latest-tweets?count=${PAGE_SIZE}`)()
    .then(response => {
      return response.data.tweets as IPicTweet[];
    });

export const callGetMoreTweets = (startAfter: any) =>
  functions
    .httpsCallable(
      `api/more-tweets?startAfter=${startAfter._seconds}&count=${PAGE_SIZE}`
    )()
    .then(response => {
      return response.data.tweets as IPicTweet[];
    });

export const getDocs = () => {
  return db
    .collection("tweets")
    .orderBy("created_at", "desc")
    .limit(PAGE_SIZE);
};

export const getMoreDocs = (startAfter: Timestamp) => {
  return db
    .collection("tweets")
    .orderBy("created_at", "desc")
    .startAfter(startAfter)
    .limit(PAGE_SIZE);
};
