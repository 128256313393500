import React, { Component } from "react";
import "./index.scss";

interface AdsenseProps {
  adClient: string;
  adSlot: string;
  adFormat: string;
  fullWidthResponsive: string;
}

export default class Adsense extends Component<AdsenseProps> {
  constructor(props: AdsenseProps) {
    super(props);
  }
  componentDidMount() {
    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  }
  render() {
    return (
      <div className="ad-container">
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client={this.props.adClient}
          data-ad-slot={this.props.adSlot}
          data-ad-format={this.props.adFormat}
          data-full-width-responsive={this.props.fullWidthResponsive}
        />
      </div>
    );
  }
}
