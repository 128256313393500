import React, { Component } from "react";
import uniqby from "lodash.uniqby";
import differenceby from "lodash.differenceby";

import PageVisibility from "react-page-visibility";
import { APP_TITLE, TWEET_LOADER_INTERVAL, CACHE_SIZE_FOR_PAUSE } from "./conf";
import { IPicTweet, AppState } from "./types";
import {
  callTweetLoader,
  callTweetCleaner,
  callGetLatestTweets,
  callGetMoreTweets
} from "./api";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PictureGridPage from "./components/PictureGridPage";

import "normalize.css";
import "./App.scss";
import Share from "./components/Share";

class App extends Component<{}, AppState> {
  loaderSubscription?: any;

  constructor(props: any) {
    super(props);
    this.state = {
      tweets: [],
      freshTweets: [],
      loadingMore: false,
      isPageVisible: true
    };
    this.loadMore = this.loadMore.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.subscribeToUpdates = this.subscribeToUpdates.bind(this);
    this.unsubscribeToUpdates = this.unsubscribeToUpdates.bind(this);
    this.updateFreshTweets = this.updateFreshTweets.bind(this);
  }
  onVisibilityChange(isPageVisible: boolean) {
    if (!isPageVisible) {
      this.unsubscribeToUpdates();
      document.title = `🏃🏽‍ ${APP_TITLE}`;
    } else if (isPageVisible) {
      this.subscribeToUpdates();
      document.title = APP_TITLE;
    }
    this.setState({
      isPageVisible
    });
  }
  subscribeToUpdates() {
    if (this.loaderSubscription) {
      console.error(
        "Subscriptions already present, this might add duplicate subscriptions"
      );
      this.unsubscribeToUpdates();
    }
    const updateTweets = (freshTweets: IPicTweet[]) => {
      if (this.state.tweets.length === 0) {
        this.setState({
          tweets: uniqby([...freshTweets, ...this.state.tweets], "id")
        });
      } else {
        this.setState({
          freshTweets: differenceby(
            uniqby([...freshTweets, ...this.state.freshTweets], "id"),
            this.state.tweets,
            "id"
          )
        });
      }
      if (this.state.freshTweets.length > CACHE_SIZE_FOR_PAUSE) {
        this.unsubscribeToUpdates();
      }
    };
    const callFns = () => {
      callTweetLoader();
      callTweetCleaner();
      callGetLatestTweets().then(updateTweets);
    };
    this.loaderSubscription = setInterval(callFns, TWEET_LOADER_INTERVAL);
    callFns();
  }
  unsubscribeToUpdates() {
    clearInterval(this.loaderSubscription);
    delete this.loaderSubscription;
  }
  componentWillMount() {
    this.subscribeToUpdates();
  }
  componentWillUnmount() {
    this.unsubscribeToUpdates();
  }
  updateFreshTweets() {
    this.setState({
      tweets: uniqby([...this.state.freshTweets, ...this.state.tweets], "id"),
      freshTweets: []
    });
    if (!this.loaderSubscription) {
      this.subscribeToUpdates();
    }
  }
  loadMore() {
    const last = this.state.tweets[this.state.tweets.length - 1];
    this.setState({ loadingMore: true });
    callGetMoreTweets(last.created_at).then((newTweets: IPicTweet[]) => {
      this.setState({
        tweets: uniqby([...this.state.tweets, ...newTweets], "id"),
        loadingMore: false
      });
    });
  }

  render() {
    return (
      <PageVisibility onChange={this.onVisibilityChange}>
        <div className="App">
          <Header />
          <div className="content">
            {this.state.tweets.length > 0 ? (
              <PictureGridPage
                tweets={this.state.tweets}
                loadMore={this.loadMore}
                loading={this.state.loadingMore}
                freshTweets={this.state.freshTweets}
                updateFreshTweets={this.updateFreshTweets}
              />
            ) : null}
          </div>
          <Share />
          <Footer />
        </div>
      </PageVisibility>
    );
  }
}

export default App;
