import React, { Component } from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-images";
import ScrollToTop from "react-scroll-up";
import "./index.scss";
import Adsense from "../Adsense";
import { IGalleryPhoto, PicturePageProps } from "../../types";
import ProfilePic from "../ProfilePic";
import UpArrow from "../../svg/up-arrow.svg";
import PicSizeSelector from "../PicSizeSelector";

export interface PicturePageState {
  size: string;
  currentImage: number;
  lightboxIsOpen: Boolean;
}

const MAX_PIC_WIDTH_MAP: { [index: string]: number } = {
  small: 200,
  medium: 300,
  large: 600
};
const LOCALSTORAGE_SIZE = "npp_size";
const SCROLL_DELAY = 2.5 * 1000;

const Spinner = () => (
  <div className="app-spinner-container">
    <div className="spinner" />
  </div>
);
const TopAdContainer = (
  <div className="top-ad-container">
    <Adsense
      adClient={"ca-pub-7576293061984551"}
      adSlot={"1148154277"}
      adFormat={"auto"}
      fullWidthResponsive={"true"}
    />
  </div>
);
const srcSet = (src: string, picSizes: any) =>
  Object.keys(picSizes)
    .map(key => `${src}:${key} ${picSizes[key].w}w`)
    .join(",");
export default class PicturePage extends Component<
  PicturePageProps,
  PicturePageState
> {
  scrollSubscription?: any;

  constructor(props: PicturePageProps) {
    super(props);
    this.state = {
      size: "small",
      currentImage: 0,
      lightboxIsOpen: false
    };
    this.columns = this.columns.bind(this);
    this.onSizeChange = this.onSizeChange.bind(this);

    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  componentWillMount() {
    setTimeout(() => {
      const size =
        (localStorage && localStorage.getItem(LOCALSTORAGE_SIZE)) || "small";
      this.setState({
        size
      });
    });
  }
  componentWillUnmount() {
    this.removeScrollSubscription();
  }
  columns(containerWidth: number): number {
    return Math.max(
      Math.floor(containerWidth / MAX_PIC_WIDTH_MAP[this.state.size]),
      1
    );
  }
  onSizeChange(size: string) {
    this.setState({
      size
    });
    setTimeout(() => {
      localStorage && localStorage.setItem(LOCALSTORAGE_SIZE, size);
    });
  }
  addScrollSubscription() {
    if (!this.scrollSubscription) {
      this.scrollSubscription = setInterval(() => {
        let currentImage = this.state.currentImage + 1;
        if (currentImage + 5 > this.props.tweets.length) {
          this.props.loadMore();
        }
        if (currentImage >= this.props.tweets.length) {
          currentImage = 0;
          if (this.props.freshTweets.length > 0) {
            this.props.updateFreshTweets();
          }
        }
        this.setState({ currentImage });
      }, SCROLL_DELAY);
    }
  }
  removeScrollSubscription() {
    if (this.scrollSubscription) {
      clearInterval(this.scrollSubscription);
      delete this.scrollSubscription;
    }
  }
  openLightbox(event: any, obj: any) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
    this.addScrollSubscription();
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
    this.removeScrollSubscription();
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() {
    const photos: IGalleryPhoto[] = this.props.tweets.map(tweet => ({
      src: tweet.media_url_https,
      width: tweet.sizes.medium.w,
      height: tweet.sizes.medium.h,
      expanded_url: tweet.expanded_url,
      screen_name: tweet.screen_name,
      picSizes: tweet.sizes,
      key: tweet.id,
      srcSet: srcSet(tweet.media_url_https, tweet.sizes)
    }));
    return photos.length > 0 ? (
      <div>
        {this.props.freshTweets.length > 0 ? (
          <div className="load-new-container">
            <button
              className="load-new-button"
              onClick={this.props.updateFreshTweets}
            >
              See {this.props.freshTweets.length} new #newProfilePics...
            </button>
          </div>
        ) : (
          ""
        )}
        {TopAdContainer}
        <div className="picture-page">
          <PicSizeSelector
            size={this.state.size}
            onSizeChange={this.onSizeChange}
          />
          <div className="gallery-container">
            <Gallery
              photos={photos}
              ImageComponent={ProfilePic}
              direction={"row"}
              columns={this.columns}
              onClick={this.openLightbox}
            />
            <Lightbox
              images={photos}
              onClose={this.closeLightbox}
              onClickPrev={this.gotoPrevious}
              onClickNext={this.gotoNext}
              currentImage={this.state.currentImage}
              isOpen={this.state.lightboxIsOpen}
              spinner={Spinner}
            />
          </div>
          <div className="tool-bar">
            {this.props.loading ? (
              <div className="tool-bar-loading-container">
                <div className="spinner" />
              </div>
            ) : (
              <button onClick={this.props.loadMore} className="load-more">
                Show More...
              </button>
            )}
          </div>
        </div>
        <ScrollToTop showUnder={320}>
          <img src={UpArrow} alt="Scroll Up" className="scrollup-icon" />
        </ScrollToTop>
        <div className="bottom-ad-container">
          <Adsense
            adClient={"ca-pub-7576293061984551"}
            adSlot={"7232973056"}
            adFormat={"auto"}
            fullWidthResponsive={"true"}
          />
        </div>
      </div>
    ) : (
      Spinner()
    );
  }
}
