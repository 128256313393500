import React from "react";
import "./index.scss";

export default function Footer() {
  return (
    <footer>
      <div className="about">
        <h2>About #newProfilePic</h2>
        <p>
          Profile pictures tell a lot about a person, their style, personality.
          Hundreds of people update their profile pictures every minute on
          Twitter. It's really fascinating to watch them, as they are posted.
        </p>
        <p>
          This app is realtime gallery of Twitter Profile Picture updates,
          tagged with{" "}
          <a
            href="https://twitter.com/hashtag/newprofilepic"
            target="_tw"
            rel="noreferrer nofollow"
          >
            #newProfilePic
          </a>
          .
        </p>
        <p>
          Contact:{" "}
          <span>
            &#x63;&#x6F;&#x6E;&#x74;&#x61;&#x63;&#x74;&#x2B;&#x6E;&#x65;&#x77;&#x70;&#x72;&#x6F;&#x66;&#x69;&#x6C;&#x65;&#x70;&#x69;&#x63;&#x40;&#x61;&#x70;&#x70;&#x62;&#x2E;&#x69;&#x6E;
          </span>{" "}
        </p>
      </div>
      <div className="attribution">
        <h2>Attribution</h2>
        <ul>
          <li>
            <a
              href="https://developer.twitter.com/"
              rel="noreferrer nofollow"
              target="_blank"
            >
              Twitter API
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/"
              rel="noreferrer nofollow"
              target="_blank"
            >
              Firebase
            </a>
          </li>
          <li>
            Icons by{" "}
            <a
              href="https://www.flaticon.com/authors/good-ware"
              title="Good Ware"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Good Ware
            </a>
            ,
            <a
              href="https://www.flaticon.com/authors/alessio-atzeni"
              title="Alessio Atzeni"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Alessio Atzeni
            </a>{" "}
            from{" "}
            <a
              href="https://www.flaticon.com/"
              title="Flaticon"
              target="_blank"
              rel="noreferrer nofollow"
            >
              www.flaticon.com
            </a>
            , licensed by{" "}
            <a
              href="http://creativecommons.org/licenses/by/3.0/"
              title="Creative Commons BY 3.0"
              target="_blank"
              rel="noreferrer nofollow"
            >
              CC 3.0 BY
            </a>
          </li>
        </ul>
      </div>
      <div className="terms">
        <h2>Terms, Privacy, blah, blah...</h2>
        <p>
          By using this website, you agree to the terms & conditions mentioned
          below.
        </p>
        <ul>
          <li>
            Images displayed are uploaded by Twitter users, and they hold
            whatever applicable rights. We only display them here and do not
            claim any rights.
          </li>
          <li>This image gallery is provided only for your entairnment.</li>
          <li>You agree not to use this website for evil purposes.</li>
          <li>
            We run analytics and ads on this page, which might use cookies.
          </li>
          <li>
            We reserve the right to change these terms at any point of time.
          </li>
        </ul>
      </div>
    </footer>
  );
}
